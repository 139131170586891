
const router_modular = [
    {
        path: '/',
        name: 'login',
        meta:{
            title: '登录',
            requireAuth: false
        },
        component: () => import('@/views/login')
    },
    {
        path: '/agreement',
        name: 'agreement',
        meta:{
            title: '用户协议',
            requireAuth: false
        },
        component: () => import('@/views/agreement')
    },
    {
        path: '/fundManage',
        name: 'fundManage',
        meta:{
            title: '资金管理',
            requireAuth: true// 需要登陆权限
        },
        component: () => import('@/views/fundManage')
    },
    {
        path: '/fundDtl',
        name: 'fundDtl',
        meta:{
            title: '收支明细',
            requireAuth: true// 需要登陆权限
        },
        component: () => import('@/views/fundDtl')
    },
    {
        path: '/recharge',
        name: 'recharge',
        meta:{
            title: '充值',
            requireAuth: true// 需要登陆权限
        },
        component: () => import('@/views/recharge')
    },
    {
        path: '/rechargeWaiting',
        name: 'rechargeWaiting',
        meta:{
            title: '充值',
            requireAuth: true// 需要登陆权限
        },
        component: () => import('@/views/rechargeWaiting')
    },
    {
        path: '/rechargeRecord',
        name: 'rechargeRecord',
        meta:{
            title: '充值记录',
            requireAuth: true// 需要登陆权限
        },
        component: () => import('@/views/rechargeRecord')
    },
    {
        path: '/rechargeSuccess',
        name: 'rechargeSuccess',
        meta:{
            title: '充值',
            requireAuth: true// 需要登陆权限
        },
        component: () => import('@/views/rechargeSuccess')
    },
    {
        path: '/transferRecords',
        name: 'transferRecords',
        meta:{
            title: '转账记录',
            requireAuth: true// 需要登陆权限
        },
        component: () => import('@/views/transferRecords')
    },
    {
        path: '/transferFirst',
        name: 'transferFirst',
        meta:{
            title: '转账',
            requireAuth: true// 需要登陆权限
        },
        component: () => import('@/views/transferFirst')
    },
    {
        path: '/transferSecond',
        name: 'transferSecond',
        component: () => import('@/views/transferSecond')
    },
    {
        path: '/transferSuccess',
        name: 'transferSuccess',
        meta:{
            title: '转账成功',
            requireAuth: true// 需要登陆权限
        },
        component: () => import('@/views/transferSuccess')
    },
    {
        path: '/adviseList',
        name: 'adviseList',
        meta:{
            title: '顾问名册',
            requireAuth: true// 需要登陆权限
        },
        component: () => import('@/views/adviseList')
    },
    {
        path: '/fwh',
        name: 'fwh',
        meta:{
            title: '服务号',
            requireAuth: false
        },
        component: () => import('@/views/fwh')
    }
]
export default router_modular