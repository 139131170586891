import Vue from 'vue'
import VueRouter from 'vue-router'
import router_modular from '@/router/router_modular/index.js'
Vue.use(VueRouter)

const routes = [
  ...router_modular
]

const router = new VueRouter({
  routes
})
//全局路由守卫
router.beforeEach((to,from,next)=>{
  // console.log('2',to, from);
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  // if (to.path == '/jumpSignature') {
  //   return next({path:'/jumpSignature'})
  // } else {
  //   next()
  // }
  // if (to.meta && to.meta.requireAuth == false) {
  //   console.log('33333')
    next()
  //   return
  // }
})
router.afterEach((to,from)=>{
  console.log(to,from);
})
export default router
